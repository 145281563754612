angular.module('MyHippoProducer.Controllers').controller('PolicyDetailsV2Controller', function ($scope, $interval, PolicyService, $state, $stateParams, WorkflowAdapter, $mdDialog, EndorsementService) {
    $scope.page = $stateParams.page;
    $scope.isEditMode = PolicyService.isEditMode;
    $scope.PolicyService = PolicyService;

    $scope.hasPageErrors = WorkflowAdapter.pageHasErrors;
    $scope.sectionHidden = (pages) => _.every(pages, (page) => WorkflowAdapter.getPage(page) === undefined);

    // Policy locking mechanism
    $scope.$watch('isEditMode', (newVal) => {
        if (newVal && !$scope.lockInterval) {
            PolicyService.lockPolicy(PolicyService.id);
            $scope.lockInterval = $interval(function () {
                PolicyService.lockPolicy(PolicyService.id);
            }, 5000);
        } else if (!newVal && $scope.lockInterval) {
            $scope.lockInterval = null;
            $interval.cancel($scope.lockInterval);
            PolicyService.unlockPolicy(PolicyService.id);
        }
    });

    $scope.$on('$destroy', function () {
        if ($scope.lockInterval) {
            $interval.cancel($scope.lockInterval);
            $scope.lockInterval = null;
            PolicyService.unlockPolicy(PolicyService.id);
        }
    });


    $scope.sections = [
        { key: 'policy_information', label: 'Policy Information'},
        { key: 'personal_information', label: 'Personal Information', pages: ['personal_information_page'] },
        { key: 'discounts', label: 'Discounts', pages: ['discount_page'] },
        { key: 'coverages', label: 'Coverages', pages: ['adjustments_page'] },
        {
            key: 'optional',
            label: 'Optional',
            pages: [
                'optional_additional_interest_third_party_page',
                'optional_property_page',
                'optional_liability_page',
                'optional_business_page',
            ],
            subsections: [
                { key: 'additional_insured', label: 'Additional Insured', pages: ['optional_additional_interest_third_party_page'] },
                { key: 'scheduled_property', label: 'Scheduled Property', pages: ['optional_property_page'] },
                { key: 'liability', label: 'Liability', pages: ['optional_liability_page'] },
                { key: 'business_coverage', label: 'Business Coverage', pages: ['optional_business_page'] },
            ],
        },
        { key: 'mortgage_information', label: 'Mortgage Information', pages: ['mortgages_page'] },
        { key: 'payment_information', label: 'Payment Information', pages: ['checkout_page'] },
        { key: 'billing', label: 'Billing' },
        { key: 'all_documents', label: 'All Documents'},
        { key: 'start_endorsement', label: 'Endorsements', visible: !$scope.isEditMode && PolicyService.allowEndorsement },
    ];

    // Make sure section exists, otherwise move to first available section
    const currentSection = $scope.sections.find(section => {
        const isSection = section.key === $scope.page;
        const isSubsection = section.subsections && section.subsections.find(sub => sub.key === $scope.page);
        return isSection || isSubsection;
    });

    if (currentSection.visible === false) {
        const firstSectionPage = $scope.sections[0].key;
        $state.go('portal.policyDetailsV2', _.assign({}, $stateParams, { page: firstSectionPage }));
    }

    $scope.isActive = (section) => {
        if (section.key === $scope.page) {
            return true;
        } else if (section.subsections && section.subsections.find(s => s.key === $scope.page)) {
            return true;
        }
        return false;
    };

    const findSectionByKey = (key) => $scope.sections.find(section => section.key === key);
    $scope.changePage = function ($event, key) {
        $event.stopPropagation();
        let nextPage = key;

        const section = findSectionByKey(key);
        if (section && section.subsections) {
            nextPage = section.subsections[0].key;
        }

        if ($scope.page === nextPage) {
            return false;
        }

        $state.go('portal.policyDetailsV2', { page: nextPage, id: $stateParams.id });
    };

    // Policy Actions
    const setViewMode = () => {
        $state.go('portal.policyDetailsV2', _.assign({}, $stateParams, { mode: 'view' }));
    };
    $scope.close = () => {
        const updatesByPage = WorkflowAdapter.separateUpdateByPages();
        if (_.isEmpty(updatesByPage)) {
            setViewMode();
        } else {
            $mdDialog.show({
                template: `
                    <basic-modal
                        title="'There are Unsaved Changes'"
                        primary-text="'Yes'"
                        secondary-text="'No'"
                    >
                        <p class="simple-text center">Are you sure you want to cancel?</p>
                    </basic-modal
                `,
            }).then(() => {
                setViewMode();
            });
        }
    };
    $scope.save = () => {
        $mdDialog.show({
            scope: $scope,
            preserveScope: true,
            parent: angular.element(document.body),
            template: '<save-endorsement-modal></save-endorsement-modal>',
        });
    };

    $scope.hasUpdates = false;

    $scope.updateEndorsement = EndorsementService.updateEndorsement;

    $scope.$watch(() => PolicyService.getUpdates(), function(newVal, oldVal) {
        if(newVal !== oldVal) {
            $scope.hasUpdates = Object.keys(newVal).length > 0;
        }
    }, true);
});
